/* Dark mode */

[data-theme="dark"]:root {
	--pw-violet: #936fff;
}

[data-theme="dark"] body {
	margin: 0;
	font-family: 'Poppins', sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: var(--pw-gray);
	text-align: left;
	background-color: var(--pw-gray-darker);
//	background-image: url("../../public/img/paws.png");
	background-repeat: repeat;
	background-size: 150px;
	background-position: 0rem;
	background-attachment: fixed;
	word-wrap: break-word;
}

[data-theme="dark"] .main-header {
	border-bottom: var(--pw-gray-darker);
}

[data-theme="dark"] .white-bg {
	padding: .5rem;
	background-color: var(--pw-gray-darkest);
	border: var(--pw-gray-darkest);
	border-radius: .25rem;
}

[data-theme="dark"] .bg-white,
[data-theme="dark"] .bg-light {
	background-color: var(--pw-gray-darkest) !important;
	border: var(--pw-gray-darkest);
	border-radius: .25rem;
	color: white !important;
}

[data-theme="dark"] .white-bg .card {
	background-color: var(--pw-gray-darker);
}

[data-theme="dark"] .page-helper{
	color: var(--pw-gray);
}

[data-theme="dark"] .white-bg textarea,
[data-theme="dark"] .white-bg select,
[data-theme="dark"] .white-bg input,
[data-theme="dark"] .white-bg .select2-selection,
[data-theme="dark"] .modal-content textarea,
[data-theme="dark"] .modal-content select,
[data-theme="dark"] .modal-content input,
[data-theme="dark"] .select2-dropdown,
[data-theme="dark"] .select2-dropdown input,
[data-theme="dark"] .tab-content select,
[data-theme="dark"] .tab-content input {
	background-color: var(--pw-gray-darker);
	color: var(--pw-gray-empty);
	border-color: var(--pw-gray-darker);
}

/*Selected2 Selected input*/
[data-theme="dark"] .white-bg .select2-container--default .select2-selection--single .select2-selection__rendered {
	color: var(--pw-primary);
}

/*Select2 Selection*/
[data-theme="dark"] .select2-container--default .select2-results__option[aria-selected=true] {
	background-color: var(--pw-primary-light);
}
/*Select2 Selection:Hover*/
[data-theme="dark"] .select2-container--default .select2-results__option[aria-selected=true]:hover {
	background-color: var(--pw-primary-dark);
}

/*Select2 Selected:Hover*/
[data-theme="dark"] .select2-container--default .select2-results__option--highlighted[aria-selected],
[data-theme="dark"] .select2-container--default .select2-results__option--highlighted[aria-selected]:hover {
	background-color: var(--pw-primary);
}

[data-theme="dark"] .white-bg .form-control:disabled,
[data-theme="dark"] .white-bg .form-control.disabled.form-control:read-only {
	background-color: var(--pw-gray-darker);
	color: var(--pw-gray-dark);
}

[data-theme="dark"] .white-bg .form-control:read-only{
	background-color: var(--pw-gray-darker);
	color: var(--pw-gray-empty);
}

[data-theme="dark"] .white-bg textarea:focus,
[data-theme="dark"] .white-bg select:focus,
[data-theme="dark"] .white-bg input:focus,
[data-theme="dark"] .white-bg .select2-container--open .selection>span,
[data-theme="dark"] .select2-container--default .select2-dropdown,
[data-theme="dark"] .select2-dropdown input span,
[data-theme="dark"] .modal-content textarea:focus,
[data-theme="dark"] .modal-content input:focus,
[data-theme="dark"] .custom-file-label,
[data-theme="dark"] .tab-content select:focus,
[data-theme="dark"] .tab-content input:focus {
	background-color: var(--pw-gray-darker);
	color: var(--pw-gray-empty);
	border-color: var(--pw-gray-darkest);
	outline: none;
	box-shadow: 0 0 0 0.05rem var(--pw-primary);
}

[data-theme="dark"] .input-post:focus,
[data-theme="dark"] .input-comment:focus {
	right: 2px !important;
}

[data-theme="dark"] .input-post:focus~div .post-submit,
[data-theme="dark"] .input-comment:focus~.comment-submit{
	background-color: var(--pw-primary);
	color: var(--pw-gray-darkest);
}

[data-theme="dark"] textarea {
	background-color: var(--pw-gray-darker);
	color: var(--pw-gray-empty);
}

[data-theme="dark"] .input-comment {
	padding: .5rem !important;
	border: none;
	border-radius: .25rem .5rem 0rem .5rem;
}

[data-theme="dark"] .input-post {
	border: none;
}

[data-theme="dark"] .reaction-bar .first-row,
[data-theme="dark"] .reaction-bar .second-row {
	border-bottom: 1px var(--pw-gray-dark) solid;
}

[data-theme="dark"] .post-content {
	border-top: 1px var(--pw-gray-dark) solid;
	padding-top: .5rem;
}

[data-theme="dark"] .post-content .post-single {
	background-color: var(--pw-gray-darker);
}

[data-theme="dark"] .post-submit , .comment-submit {
	color: var(--pw-gray-empty);
	background-color: var(--pw-primary-darkest);
	border: 1px var(--pw-primary-darkest) solid;
}

[data-theme="dark"] .male, .male:hover, .male::after {
	color: var(--pw-blue-light) !important;
}

[data-theme="dark"] .female, .female:hover, .female::after {
	color: var(--pw-pink) !important;
}

[data-theme="dark"] .privacy-selector select, select.btn, select.btn:hover {
	background-color: var(--pw-gray-darkest);
	color: var(--pw-primary);
}

[data-theme="dark"] .modal-content {
	background-color: var(--pw-gray-darkest);
	border-color: var(--pw-gray-darker);
}

[data-theme="dark"] .modal-content .close{
	color: var(--pw-primary);
	text-shadow: none;
}

[data-theme="dark"] .dropdown-menu {
	color: var(--pw-gray-empty);
	background-color: var(--pw-gray-darker);
	border-color: var(--pw-gray-dark);
}

[data-theme="dark"] .dropdown-item {
	color: var(--pw-gray-empty);
	background-color: var(--pw-gray-darker);
}

[data-theme="dark"] .single-geotag-result {
	color: var(--pw-gray-empty) !important;
}

[data-theme="dark"] .single-geotag-result {
	color: var(--pw-gray-empty) !important;
}

[data-theme="dark"] .searchbar {
	color: var(--pw-gray-empty) !important;
	background-color: var(--pw-gray-darkest);
	border:none;
}

[data-theme="dark"] .modal-footer, .modal-header {
	border: none !important;
}

[data-theme="dark"] hr {
	background-color: var(--pw-gray-dark);
}

[data-theme="dark"] .petcoin-buy {
	color: var(--pw-black-7);
}

[data-theme="dark"] .svg-box-stroke {
	stroke:var(--pw-gray);
}

[data-theme="dark"] .svg-box-fill,
[data-theme="dark"] .svg-box-path {
	fill:var(--pw-gray);
}

[data-theme="dark"] .svg-box-font {
	font-size: 12px; font-family: MyriadPro-Regular, Myriad Pro;
	fill:var(--pw-gray);
}

/*DataTable*/
[data-theme="dark"] #dataTable thead, #dataTable tbody {
	background-color: var(--pw-gray-darker) !important;
	color: var(--pw-gray-empty);
}

[data-theme="dark"] .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgb(0 255 179 / 10%);
}

[data-theme="dark"] #dataTable th {
	background-color: var(--pw-gray-darkest);
}

[data-theme="dark"] .table thead th, .table th, .table td {
	border-color: var(--pw-gray-dark);
}

/*DateRangePicker*/
[data-theme="dark"] .daterangepicker {
	background-color: var(--pw-gray-darkest);
	border-color: var(--pw-gray-dark);
}

[data-theme="dark"] .daterangepicker .drp-buttons {
	border-top: none;
}

[data-theme="dark"] .daterangepicker .applyBtn {
	background-color: var(--pw-primary-dark);
}

[data-theme="dark"] .daterangepicker .calendar-table {
	background-color: var(--pw-gray-darker) !important;
}

[data-theme="dark"] .daterangepicker .calendar-table td.off {
	background-color: var(--pw-gray-darker) ;
	color:var(--pw-gray-dark)
}

[data-theme="dark"] .daterangepicker .calendar-table td.off.disabled {
	background-color: var(--pw-gray-darkest) !important;
	color:darkred
}

[data-theme="dark"] .daterangepicker .calendar-table td.active,
[data-theme="dark"] .daterangepicker .calendar-table td:hover {
	background-color: var(--pw-primary-dark) !important;
	color: white !important;
}

[data-theme="dark"] .daterangepicker .calendar-table .next span,
[data-theme="dark"] .daterangepicker .calendar-table .prev span {
	border-color: white !important;
}

[data-theme="dark"] .calendar-table th.next:hover,
[data-theme="dark"] .calendar-table th.prev:hover {
	background-color: var(--pw-primary-dark) !important;
	color: white !important;
}

[data-theme="dark"] .tab-content {
	background-color: var(--pw-gray-darkest) !important;
	border-color: var(--pw-primary) !important;
}

[data-theme="dark"] .nav-tabs {
	border-bottom: var(--pw-primary) solid 1px !important;
}

[data-theme="dark"] .nav-tabs .nav-link {
	background-color: var(--pw-gray-darker) !important;
	border: var(--pw-primary-dark) solid 1px !important;
}

[data-theme="dark"] .nav-tabs .nav-link.active {
	background-color: var(--pw-gray-darkest) !important;
	color: var(--pw-primary) !important;
}

[data-theme="dark"] nav.main-header {
	border-bottom: 1px solid var(--pw-gray-darkest);
}

/* SIDEBAR ADMINLTE */

[data-theme="dark"] .sidebar-dark-primary {
	background-color: var(--pw-gray-darkest);
}

[data-theme="dark"] .layout-navbar-fixed .wrapper .sidebar-dark-primary .brand-link:not([class*=navbar]) {
	background-color: var(--pw-gray-darkest); /*Background intestazione sidebar*/
	color: var(--pw-primary-light);
}

[data-theme="dark"] [class*=sidebar-dark] .brand-link,
[data-theme="dark"] [class*=sidebar-dark] .user-panel {
	border-bottom: 1px solid var(--pw-primary);
}
[data-theme="dark"] [class*=sidebar-dark-] .nav-header {
	color: var(--pw-primary-dark) !important;
	font-weight: bold;
}

/*Menu Icons and links*/
[data-theme="dark"] [class*=sidebar-dark-] .nav a,
[data-theme="dark"] [class*=sidebar-dark-] .nav a i,
[data-theme="dark"] [class*=sidebar-dark] .user-panel a {
	color: var(--pw-gray) !important;
}

/*Submenu Icons*/
[data-theme="dark"] [class*=sidebar-dark-] .nav .nav-treeview a i{
	color: var(--pw-gray) !important;
}

/*Menu links*/
[data-theme="dark"] [class*=sidebar-dark-] .nav-item{
	color: var(--pw-white) !important;
}

/*Menu active links*/
[data-theme="dark"] [class*=sidebar-dark-] .nav-item.menu-open>a.nav-link{
	color: var(--pw-primary-dark) !important;
}

[data-theme="dark"] .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
	background-color: var(--pw-gray-darkest) !important;
	border-color:  var(--pw-primary-dark) var(--pw-primary-dark) var(--pw-gray-darkest) !important;
}

/*Menu hover or focus*/
[data-theme="dark"] [class*=sidebar-dark-] .nav-item.menu-open>a.nav-link,
[data-theme="dark"] [class*=sidebar-dark-] .nav a:hover,
[data-theme="dark"] [class*=sidebar-dark-] .nav a:hover i,
[data-theme="dark"] [class*=sidebar-dark-] .nav-treeview a:hover i,
[data-theme="dark"] [class*=sidebar-dark] .user-panel a:hover {
	color: var(--pw-primary) !important;
}

/* Notifications & Friend Requests */

[data-theme="dark"] .notifications.read {
	background-color: var(--pw-black-2);
}

[data-theme="dark"] .notifications:hover {
	background-color: var(--pw-black-2);
}

[data-theme="dark"] .notifications:hover p {
	color: var(--pw-primary);
}

[data-theme="dark"] .notifications .text-sm {
	white-space: initial !important;
}

[data-theme="dark"] .dropdown-item:hover, .dropdown-item:focus {
	text-decoration: none;
	background-color: var(--pw-black-1);
	color: var(--pw-primary);
}

[data-theme="dark"] .dropdown-divider {
	border-color: var(--pw-gray-darkest);
}

[data-theme="dark"] .white-bg textarea {
	color: var(--pw-gray) !important;
}

/*[data-theme="dark"] .btn.btn-petwave,
[data-theme="dark"] .btn.btn-petwave:active,*/
[data-theme="dark"] .white-bg .card-header {
	color: rgba(0, 0, 0, 0.9);;
}

[data-theme="dark"] .card {
	background-color: var(--pw-gray-darkest);
}

[data-theme="dark"] #hiddenField{
	background-color: var(--pw-gray-darkest);
}

[data-theme="dark"] #search-more {
	background-color: var(--pw-gray-darkest);
}

[data-theme="dark"] .single-geotag-result:active,
[data-theme="dark"] .single-geotag-result:hover {
	background-color: var(--pw-primary-darkest);
}

[data-theme="dark"] #search-results {
	border: solid 1px var(--pw-primary);
}

[data-theme="dark"] .table th,
[data-theme="dark"] .table td {
	border-top: solid 1px var(--pw-primary);
}

[data-theme="dark"] #login-apple {
	background: white;
	border: none;
	color: black;
}

/*LOGIN REGISTER VITTORIO*/

[data-theme="dark"] body.login_register_page {
	background-color: var(--pw-gray-darker) !important
}

[data-theme="dark"] body .carousel-inner {
	background-color: var(--pw-gray-darkest) !important
}

[data-theme="dark"] .login_register_page .navbar {
	background: var(--pw-primary)
}

[data-theme="dark"] .login_register_page .box-vittorio,
[data-theme="dark"] .login_register_page .box-vittorio-pet-ask {
	background-color: var(--pw-primary) !important
}

[data-theme="dark"] .login_register_page nav.main-header h2,
[data-theme="dark"] .login_register_page nav.main-header i.mdi {
	color: var(--pw-gray-darkest) !important
}

[data-theme="dark"] .login_register_page .testo-verde-vittorio-titolo,
[data-theme="dark"] .login_register_page .testo-verde-vittorio-post-chiedi-vet {
	color: var(--pw-gray-darker) !important
}

[data-theme="dark"] .login_register_page .testo-vittorio-viola,
[data-theme="dark"] .login_register_page .testo-grigio-vittorio-post-chiedi-vet {
	color: var(--pw-gray-dark) !important
}

[data-theme="dark"] .login_register_page .carousel-item .pw-primary-dark,
[data-theme="dark"] .login_register_page .titolo-vittorio-viola{
	color: var(--pw-primary-dark) !important
}

[data-theme="dark"] .login_register_page .btn.btn-petwave {
	background-color: var(--pw-primary-dark) !important;
	border-color: var(--pw-primary-dark) !important
}

[data-theme="dark"] .login_register_page .btn.btn-petwave.vittorio-social-button,
[data-theme="dark"] .login_register_page .form-control {
	background-color: var(--pw-gray-darkest) !important;
	border-color: var(--pw-gray-dark) !important;
	color: var(--pw-gray-light) !important;
}

[data-theme="dark"] #post-tag li,
[data-theme="dark"] .post-tag-edit li {
	background-color: var(--pw-gray-darkest) !important;
	color: var(--pw-gray-light) !important;
}

[data-theme="dark"] #post-tag li:hover,
[data-theme="dark"] .post-tag-edit li:hover {
	background-color: var(--pw-primary-darkest) !important;
	color: var(--pw-gray-light) !important;
}

[data-theme="dark"] .btn.btn-outline-petwave-black {
	background-color: none;
	color: var(--pw-white);
	border-color: var(--pw-white);
}

[data-theme="dark"] .btn.btn-outline-petwave-black:hover,
[data-theme="dark"] .btn.btn-outline-petwave-black.active {
	background-color: var(--pw-white);
	color: var(--pw-gray-darkest);
	border-color: var(--pw-white);
}


/*LOGIN REGISTER VITTORIO*/

[data-theme="dark"] .title-card-black,
[data-theme="dark"] .title-card-black-small,
[data-theme="dark"] .title-card-black-buy{
	color: var(--pw-white) !important;
}

/*BARRA INFO PETCOIN - NUOVA VERSIONE - FORMATO LIGHT - SETTEMBRE 2021 - petcoinbar_noselect.blade */
[data-theme="dark"] .petcoin_bar_info_text{
	color: var(--pw-white) !important;
}


[data-theme="dark"] #side_navbar {
    background: var(--pw-gray-darkest);
}

[data-theme="dark"] #side_navbar a,
[data-theme="dark"] #side_navbar .accordion,
[data-theme="dark"] #side_navbar .panel {
	color: var(--pw-gray-light);
}

[data-theme="dark"] #side_navbar .social .accordion {
	background-color: var(--pw-primary-darker);
}

[data-theme="dark"] #side_navbar .aroundme .accordion {
	background-color: var(--pw-green-darker);
}

[data-theme="dark"] #side_navbar .community .accordion {
	background-color: var(--pw-blue-darker);
}

[data-theme="dark"] #side_navbar .adoptions .accordion {
	background-color: var(--pw-violet-dark);
}

[data-theme="dark"] #side_navbar .sponsor .accordion {
	background-color: var(--pw-orange-darker);
}

[data-theme="dark"] #side_navbar .petcoin .accordion {
	background-color: var(--pw-yellow-darker);
}

[data-theme="dark"] .pet-int-label {
	color: var(--pw-primary);
	font-size: 1rem;
}

// Chat

[data-theme="dark"] .pet-int-label {
	color: var(--pw-primary);
	font-size: 1rem;
}

[data-theme="dark"] .chat-bubble {
	color: var(--pw-white) !important;
}

[data-theme="dark"] .bubble-owner {
	background-color: var(--pw-primary-darkest);
	border-radius: .5rem;
}

[data-theme="dark"] .bubble-other {
	background-color: var(--pw-gray-darker);
	border-radius: .5rem;
}

[data-theme="dark"] .message-sent a,
[data-theme="dark"] .message-received a {
	color: var(--pw-primary-light);
}